import { createContext, useState } from "react";

const MealContext = createContext();

export const MealProvider = ({ children }) => {
  const [singleMeal, setSingleMeal] = useState({});

  const [searchedMeals, setSearchedMeals] = useState([]);
  const [mealsInfo, setMealsInfo] = useState([]);
  const [randomeMealsInfo, setRandomMealsInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Fectch a random meal

  const randomMeals = async () => {
    setLoading(true);
    const res = await fetch(
      `https://api.spoonacular.com/recipes/random?number=100&apiKey=${process.env.REACT_APP_API_KEY}`
    );
    const { recipes } = await res.json();
    // setMeal(recipes[0]);
    setRandomMealsInfo(recipes);
    setLoading(false);
  };

  // Search meal by name
  // `https://api.spoonacular.com/recipes/informationBulk?ids=${ids}&includeNutrition=true&apiKey=${process.env.REACT_APP_API_KEY}`
  const searchMealInfo = async (...ids) => {
    const res = await fetch(
      `https://api.spoonacular.com/recipes/informationBulk?ids=${ids}&includeNutrition=true&apiKey=${process.env.REACT_APP_API_KEY}`
    );
    const data = await res.json();
    // console.log(data.results);
    setMealsInfo(data.results);
  };

  const searchMeal = async (text = "food") => {
    const res = await fetch(
      `https://api.spoonacular.com/recipes/complexSearch?query=${text}&apiKey=${process.env.REACT_APP_API_KEY}`
    );
    const data = await res.json();

    setSearchedMeals(data.results);
  };

  /// Get meal by id
  const getSingleMeal = async (id) => {
    const res = await fetch(
      `https://api.spoonacular.com/recipes/${id}/information?apiKey=${process.env.REACT_APP_API_KEY}`
    );
    const data = await res.json();
    setSingleMeal(data);
  };
  const clearMeal = () => {
    setSearchedMeals([]);
  };
  const currentPageNumber = currentPage * itemsPerPage - itemsPerPage;
  const currentMealsPage = (meals) => {
    return meals?.slice(currentPageNumber, itemsPerPage * currentPage);
  };
  const currentMeals = randomeMealsInfo?.slice(
    currentPageNumber,
    itemsPerPage * currentPage
  );
  const handlePrev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const handlePageChange = (e, page) => {
    e.preventDefault();

    setCurrentPage(page);
  };

  // useEffect(() => {
  // 	randomMeals();

  // }, []);
  return (
    <MealContext.Provider
      value={{
        // meal,
        randomMeals,
        searchMealInfo,
        currentMealsPage,
        currentPage,
        setCurrentPage,
        searchedMeals,
        mealsInfo,
        searchMeal,
        handlePrev,
        getSingleMeal,
        singleMeal,
        loading,
        randomeMealsInfo,
        clearMeal,
        currentMeals,
        handleNext,
        handlePageChange,
      }}
    >
      {children}
    </MealContext.Provider>
  );
};
export default MealContext;
