import React, { useEffect } from "react";

// import dotenv from 'dotenv';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
  useHistory,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import { ThemeProvider } from "@material-ui/core";
import PageLayout from "./components/layout/PageLayout";
import { theme } from "./theme";

const Home = React.lazy(() => import("./components/Home"));
const Meals = React.lazy(() => import("./components/Meals"));
const SingleMeal = React.lazy(() => import("./components/SingleMeal"));
const Favorites = React.lazy(() => import("./components/pages/Favorites"));
const SearchResults = React.lazy(() =>
  import("./components/pages/SearchResults")
);

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PageLayout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/search" component={SearchResults} />

            <Route exact path="/favorites" component={Favorites} />
            <Route exact path="/meals/:id" component={SingleMeal} />

            <Route exact path="/meals" component={Meals} />
          </Switch>
        </PageLayout>
      </ThemeProvider>
    </>
  );
};
export default App;
