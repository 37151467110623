import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  randomMealsInfo: null,
  meals: null,
  searchMealsInfo: null,
  searchMeals: null,
  searchText: "",
  mealsInfoRandom: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  meal: {},
  userBookmarks: JSON.parse(localStorage.getItem("meals")) || [],
  singleMeal: null,
  //   JSON.parse(localStorage.getItem("userMeals")) ||
};

export const getRandomMealsInfo = createAsyncThunk("meals/random", async () => {
  const response = await axios.get(
    `https://api.spoonacular.com/recipes/random?number=100&apiKey=${process.env.REACT_APP_API_KEY}`
  );
  // const response = await axios.get(
  // 	`https://api.spoonacular.com/recipes/random?number=100&apiKey=31067024960a4cc19b129ddd322bb127`
  // );
  const { recipes } = await response.data;
  return recipes;
});
export const getSearchMeals = createAsyncThunk("meals/search", async (text) => {
  if (!text) {
    return null;
  }
  const response = await axios.get(
    `https://api.spoonacular.com/recipes/complexSearch?query=${text}&number=100&apiKey=${process.env.REACT_APP_API_KEY}`
  );
  const data = await response.data;

  return data?.results;
});

export const bookmarkMeal = createAsyncThunk("meals/bookmark", async (meal) => {
  const meals = JSON.parse(localStorage.getItem("meals")) ?? [];
  meals.push(meal);
  localStorage.setItem("meals", JSON.stringify(meals));
  return meal;
});
export const setSearchText = createAsyncThunk("meals/search", async (text) => {
  return text;
});
export const getSingleMeal = createAsyncThunk("meal/single", async (id) => {
  const meals = JSON.parse(localStorage.getItem("meals")) ?? [];

  if (meals?.length > 0) {
    const meal = meals?.filter((m) => m.id === id)[0];
    if (meal !== undefined) {
      return meal;
    } else {
      return id;
    }
  }
});

export const getSearchMealsInfo = createAsyncThunk(
  "meals/searchInfo",
  async (ids) => {
    const mealIds = [...ids].join(",");
    const response = await axios.get(
      `https://api.spoonacular.com/recipes/informationBulk?ids=${mealIds}&includeNutrition=true&apiKey=${process.env.REACT_APP_API_KEY}`
    );
    const data = await response.data;

    return data.results;
  }
);
export const getMealInfo = createAsyncThunk("meals/mealInfo", async (id) => {
  const response = await axios.get(
    `https://api.spoonacular.com/recipes/${id}/information?&includeNutrition=true&apiKey=${process.env.REACT_APP_API_KEY}`
  );
  const data = await response.data;

  return data;
});

// const searchMeal = async (text = 'food') => {
// 	const res = await fetch(
// 		`https://api.spoonacular.com/recipes/complexSearch?query=${text}&apiKey=${process.env.REACT_APP_API_KEY}`
// 	);
// 	const data = await res.json();
// 	setMeals(data.results);
// };

export const mealsSlice = createSlice({
  name: "meal",
  initialState,
  reducers: {
    createSingle: (state, { payload }) => {
      const meals = JSON.parse(localStorage.getItem("meals")) ?? [];
      if (meals?.length > 0) {
        const meal = meals?.find((m) => m.id === payload);
        if (meal !== -1) {
          return {
            ...state,
            singleMeal: meal,
          };
        } else {
          return {
            ...state,
            singleMeal: [...state.mealsInfoRandom].filter(
              (m) => m.id === payload
            )[0],
          };
        }
      }
    },
    clearSearchMeals: (state, action) => {
      state.searchMeals = null;
      state.searchText = "";
      return state;
    },
  },
  extraReducers: {
    [getRandomMealsInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [getRandomMealsInfo.fulfilled]: (state, action) => {
      state.mealsInfoRandom = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [getRandomMealsInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload;
    },
    //
    [bookmarkMeal.pending]: (state) => {
      state.isLoading = true;
    },
    [bookmarkMeal.fulfilled]: (state, action) => {
      state.userBookmarks = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [bookmarkMeal.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload;
    },
    [getSingleMeal.pending]: (state) => {
      state.isLoading = true;
    },
    [getSingleMeal.fulfilled]: (state, action) => {
      const m = action.payload;

      if (typeof m === "number") {
        const meals = state.mealsInfoRandom;
        const meal = meals?.filter((m) => m.id === m)[0];
        state.singleMeal = meal;
      }

      if (typeof m !== "undefined") {
        state.singleMeal = action.payload;
      }

      state.isLoading = false;
      state.isSuccess = true;
    },
    [getSingleMeal.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.message = action.payload;
    },
    //
    [getSearchMeals.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getSearchMeals.fulfilled]: (state, action) => {
      state.searchMeals = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [getSearchMeals.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      // state.message = action.payload;
    },
    //
    [getSearchMealsInfo.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getSearchMealsInfo.fulfilled]: (state, action) => {
      state.searchMealsInfo = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [getSearchMealsInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
    },
    [getMealInfo.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getMealInfo.fulfilled]: (state, action) => {
      state.meal = action.payload;
      // state.singleMeal = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    [getMealInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
    },
    // [setSearchText.pending]: (state, action) => {
    //   state.isLoading = true;
    // },
    // [setSearchText.fulfilled]: (state, action) => {
    //   state.searchText = action.payload;
    //   state.isLoading = false;
    //   state.isSuccess = true;
    // },
    // [setSearchText.rejected]: (state, action) => {
    //   state.isLoading = false;
    //   state.isError = true;
    //   state.isSuccess = false;
    //   state.message = action.payload;
    // },

    // [clearSearchMeals.pending]: (state, action) => {
    //   state.isLoading = true;
    // },
    // [clearSearchMeals.fulfilled]: (state, action) => {
    //   state.searchMeals = null;
    //   state.searchText = "";

    //   state.isLoading = false;
    //   state.isSuccess = true;
    // },
    // [clearSearchMeals.rejected]: (state, action) => {
    //   state.isLoading = false;
    //   state.isError = true;
    //   state.isSuccess = false;
    //   state.message = action.payload;
    // },
  },
});
// const handlePrev = () => {
// 	if (currentPage === 1) return;
// 	setCurrentPage(currentPage - 1);
// };
// const handleNext = () => {
// 	setCurrentPage(currentPage + 1);
// };
// const handlePageChange = (value) => {
// 	setCurrentPage(value);
// };

const { actions, reducer } = mealsSlice;
export const { createSingle, clearSearchMeals } = actions;
export default reducer;
