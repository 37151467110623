import { createTheme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

export const theme = createTheme({
	// palette: {
	// 	primary: {
	// 		main: '#FFFFFF',
	// 	},
	// 	secondary: blue,
	// },
	myButton: {
		backgroundColor: blue,
		color: '#fff',
		border: '#1px ',
	},
});
