import { configureStore } from '@reduxjs/toolkit';
// import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import mealsReducer from '../features/meals/mealsSlice';

import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// export const store = configureStore({
// 	reducer: {
// 		meals: mealsReducer,
// 		// youtube: youtubeReducer,
// 		// achievements: achievementReducer,
// 		// members: memberReducer,
// 	},
// });

const createNoopStorage = () => {
	return {
		getItem(_key) {
			return Promise.resolve(null);
		},
		setItem(_key, value) {
			return Promise.resolve(value);
		},
		removeItem(_key) {
			return Promise.resolve();
		},
	};
};

const storage =
	typeof window !== 'undefined'
		? createWebStorage('local')
		: createNoopStorage();
const persistConfig = {
	key: 'root',
	version: 1,
	storage,
};
const reducers = combineReducers({
	meals: mealsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk],
});

export default store;
