// import { FastfoodSharp, HomeOutlined } from '@material-ui/icons';
import React, { useState, Suspense } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { alpha, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import CssBaseline from "@material-ui/core/CssBaseline";

import {
  FormControl,
  Input,
  List,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, useHistory } from "react-router-dom";

import ClearIcon from "@material-ui/icons/Clear";
import { useDispatch } from "react-redux";
import { getSearchMeals } from "../../features/meals/mealsSlice";
import Favorite from "@material-ui/icons/Favorite";
import { Home, RestaurantMenu } from "@material-ui/icons";
import Footer from "../Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  page: {
    minHeight: "100vh",
    paddingBottom: "2.5rem",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    backgroundColor: alpha(theme.palette.common.black, 0.85),
  },
  title: {
    flexGrow: 1,
    display: "none",
    color: "#fff",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    fontWeight: "bold",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.2),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    marginLeft: "100%",
    display: "flex",
  },
  clIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    color: "#fff",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

const pages = [
  { url: "/", name: "Home", icon: <Home /> },
  { url: "/favorites", name: "Favorites", icon: <Favorite /> },
  { url: "/meals", name: "Meals", icon: <Home /> },
  { url: "/account", name: "Account", icon: <Home /> },
];
const PageLayout = ({ children }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const history = useHistory();
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const dispatch = useDispatch();
  const [text, setText] = useState();

  // const url = `https://api.spoonacular.com/recipes/complexSearch?query=${term}`;
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getSearchMeals(text));
    history.push("/search");
  };
  const handleClearSearch = (e) => {
    e.preventDefault();
    setText("");
    dispatch(getSearchMeals(null));
  };

  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pages?.map(({ name, url, icon }) => (
          <ListItem button key={name} onClick={() => history.push(`${url}`)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );
  return (
    <>
      <div className={classes.root}>
        <CssBaseline />

        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >
              <React.Fragment key="left">
                <MenuIcon onClick={toggleDrawer("left", true)} />
              </React.Fragment>
            </IconButton>

            <Typography className={classes.title} variant="body1" noWrap>
              <Link
                to="/"
                className="decoration-none"
                style={{ ...styles.link, color: "white" }}
              >
                Random Meals <RestaurantMenu color="secondary" />
              </Link>
            </Typography>
            <FormControl
              component="form"
              onSubmit={handleSubmit}
              className={classes.search}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e);
                }
              }}
            >
              <Input
                id="input-with-icon-adornment"
                placeholder="Search meals"
                type="text"
                name="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                endAdornment={
                  <>
                    {!!text && (
                      <IconButton type="submit">
                        <SearchIcon className="text-gray-200" />
                      </IconButton>
                    )}
                    {text && (
                      <IconButton onClick={handleClearSearch}>
                        <ClearIcon className="text-gray-200" />
                      </IconButton>
                    )}
                  </>
                }
                inputProps={{ "aria-label": "search" }}
              />
            </FormControl>
            {/* </form> */}
          </Toolbar>
          <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
        </AppBar>
      </div>
      <Suspense fallback={<h1>Loading</h1>}>
        <div className={classes.page}>{children}</div>
      </Suspense>

      <Footer />
    </>
  );
};

export default PageLayout;

const styles = {
  link: {
    textDecoration: "none",
  },
};
